import * as Ajax from './ajax'
const STEKI_METER_HEIGHTS = ['0%', '25%', '33%', '40%', '48%', '56%', '65%', '74%', '83%', '92%', '100%']

/**
 * 実行可能状態
 */
console.log('chemical.js!!!!!!!!!!!');

$(document).ready(function () {
  /**
   * chemical_controller
   */
  if (document.body.dataset.controller == 'chemical') {
    /**
     * show action
     */
    if (document.body.dataset.action == 'show') {

    }

    /**
     * index action
     */
      if (document.body.dataset.action == 'index') {
        // 愛用商品取得
        getPurchaseHistoryChemical();




      // タブ処理系
      $('.wrap-content.counseling,.wrap-content.photos').hide()
      
      $('.tab').click(function () {
        console.log("tab tap")
        $('.tab').removeClass('active')
        $(this).addClass('active')
        $('.wrap-content').hide()
        $('.wrap-content.' + $(this).data('content')).show()
        

        console.log($(this).data('content'))
        //「写真」に遷移した際に非同期で写真を取得する
        if ($(this).data('content') === "photos") {
          getKartePhotoList();
        }
        //「愛用商品リスト」に遷移した際に非同期取得する
        else if ($(this).data('content') === "purchase-history") {
            getPurchaseHistoryChemical();
        }
      });

      // デフォルトタブの指定があれば選択する
      const selectTab = $('.default-tab').data('selectTab')
      if(selectTab) {
        $('.tab[data-content=' + selectTab).click()
      }
    }
  }
})

document.addEventListener("DOMContentLoaded", function() {
  var archiveListItems = document.querySelectorAll(".common-mykarte-archive-list");

  for (var i = 2; i < archiveListItems.length; i++) {
    archiveListItems[i].style.display = "block";
  }
});



function getKartePhotoList() {
  var ruid = $(".wrapper-mykarte-list").data('ruid');
  Ajax.getSharedKartePhotoList(ruid, (result) => {
    let html = "";
    if (result.karte_photos) {
      // 写真一覧をレンダリングする
      let photos = result.karte_photos;

      if (photos.length > 0) {
        html += "<ul id='rizm-photos'>";
        for (var i = 0; i < photos.length; i++) {
          html += "<li>";
          html += "<a href='chemical/" + photos[i].suidbase + "/" + photos[i].k_suid + "'>";
          html += "<div>";
          html += "<img data-src='" + photos[i].s3_image + "'/>";
          html += "</div>";
          html += "</a>";
          html += "</li>";
        }
        html += "</ul>";
      } else {
        html += "<div class='karte-empty-image'>";
        html += "<%= image_tag 'https://limehair.jp/php74-lime/wp-content/uploads/2024/02/defaultscreen-photo-hiragino.png' %>";
        html += "</div>";
      }
    } else {
      html += "<div class='karte-empty-image'>";
      html += "<img src='https://limehair.jp/php74-lime/wp-content/uploads/2024/02/defaultscreen-photo-hiragino.png'/>";
      html += "</div>";
    }
    $(".wrap-content.photos").html(html);

    // 画像の遅延読み込み
    const imageElements = document.querySelectorAll('.wrap-content.photos img[data-src]');
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          const src = image.getAttribute('data-src');
          image.setAttribute('src', src);
          image.removeAttribute('data-src');
          observer.unobserve(image);
        }
      });
    });

    imageElements.forEach((image) => {
      imageObserver.observe(image);
    });
  });
}

// 愛用商品配送ステータステキスト
function createFulfillmentStatusText(fulfillment_status) {
  switch (fulfillment_status) {
    case 0:
      return null
      break;
    case 1:
      return "発送準備中"
      break;
    case 2:
      return "発送済"
      break;
    case 3:
      return "配達完了"
      break;
  
    default:
      return null
      break;
  }
}



// 年末年始かチェック 期間は12月27日から1月4日
function isNewYearHolidaySeason() {
  // 現在の日付を取得
  const currentTime = new Date();

  // 現在の月と日を取得
  const month = currentTime.getMonth() + 1; // 0-indexedなので+1
  const date = currentTime.getDate();

  // 12月27日以降または1月4日以前かを判定
  if ((month === 12 && date >= 27) || (month === 1 && date <= 4)) {
    return true;
  }
  return false;
}



function getPurchaseHistoryChemical() {
  var ruid = $(".wrapper-mykarte-list").data('ruid');
  var liff_full_url = $('input[name=liff-full-url]').val();
  var is_production = $('input[name=is-production]').val();
  var chemical_growth_discount_first_percent = $('input[name=chemical-growth-discount-first-percent]').val();
  Ajax.getPurchaseHistoryChemical(ruid, (result) => {
      console.log('getPurchaseHistoryChemical res', result);
    let html = "";
      let purchase_history = result.purchase_history;
      
    //   alert("purchase_historyの数" + purchase_history.length)

    if (purchase_history.length > 0) {
      html += "<ul id='purchase-history-grid'>";
        for (var i = 0; i < purchase_history.length; i++) {
        //   alert("ループ回数：" + i)
        let history = purchase_history[i];
        const fulfillmentStatusText = createFulfillmentStatusText(history.order_fulfillment_status);
        console.log('fulfillmentStatusText', fulfillmentStatusText);

        html += "<li class='purchase-history-grid-child'>";
        if (history.next_order_date) {
            //   html += "<a href='" + liff_full_url + "&move_to=subscription-console&ruid=" + ruid + "'>";
            html += "<a href='#'>";
        } else {
            //   html += "<a href='" + liff_full_url + "&move_to=ec&referral_suidbase=" + history.suidbase + "&product_variants_id=" + history.chemical_product_variants_id + "&from_purchase_history=true'>";
            html += "<a href='#'>";
        }
        html += "<div class='purchase-history-grid-child-overlay'>";
        if (history.next_order_date) {
          html += "<div class='label-box'>";
          html += "<span class='purchased-category'>定期購入中</span>";
          if (fulfillmentStatusText) {
            html += "<span class='fulfillment-status-label'>" + fulfillmentStatusText + "</span>";
          }
          html += "</div>";
          html += "<p class='product-yotei'>次回" + history.next_order_date + "にお届け予定</p>";
        } else {
          if (fulfillmentStatusText) {
            html += "<div class='label-box label-box-higher'>";
            html += "<span class='fulfillment-status-label'>" + fulfillmentStatusText + "</span>";
            html += "</div>";
            html += "<p class='product-yotei'>次回" + history.next_shipping_date_begin + "にお届け予定</p>";
          } else {
            html += "<p class='purchased-date'>" + history.purchase_date +"に購入</p>";
            html += "<p class='product-name'>" + history.variant_name +"</p>";
          }
        }
        html += "</div>";
        html += "<img data-src='" + history.product_image + "'>";
        html += "</a>";
        html += "</li>";
      }
      html += "</ul>";
    //   html += "<div class='products-search-item' ><a class='products-search-link' href='" + liff_full_url + "&move_to=product-search&ruid=" + ruid + "'>他の商品を検索する></a></div>";
       
    } else {
      var ranking_products = [];
      var referral_suidbase = null;
      // ランキングに表示する商品リスト

      ranking_products =[{ variant_name: 'ＨＡＩＲ　ＢＡＬＭ　　９９７　　７０ｇ', chemical_product_variants_id: '3216', ranking_column_number: '1' },
                          { variant_name: 'ＨＡＩＲ　ＯＩＬ　９９７　　１２０ｍｌ', chemical_product_variants_id: '3226', ranking_column_number: '2' },
                          { variant_name: 'アルトオイル 50ml（Alto ORGANIC HAIR OIL）', chemical_product_variants_id: '3554', ranking_column_number: '3' },
                          { variant_name: 'パラトリートメントプラス 500mlボトル', chemical_product_variants_id: '3582', ranking_column_number: '4' },
                          { variant_name: 'The milk for hair care ( ケアミルク)', chemical_product_variants_id: '66', ranking_column_number: '5' },
                          { variant_name: 'パラシャンプープラス 500mlボトル', chemical_product_variants_id: '3578', ranking_column_number: '6' },
                          { variant_name: 'パラシャンプープラス 1000ml詰め替え用レフィル', chemical_product_variants_id: '3576', ranking_column_number: '7' },
                          { variant_name: 'トリエ　エマルジョン　　４　　１２０ｍｌ', chemical_product_variants_id: '1546', ranking_column_number: '8' },
                          { variant_name: 'アルトミスト 500ml 詰め替え用レフィル', chemical_product_variants_id: '3570', ranking_column_number: '9' },
                          { variant_name: '01 golden oil（ゼロワンゴールデンオイル）', chemical_product_variants_id: '6987', ranking_column_number: '10' },
                          { variant_name: 'Ｘ　ＴＲＥＡＴＭＥＮＴ　シャンプー　５００ｍｌ　ボトル', chemical_product_variants_id: '3534', ranking_column_number: '11' },
                          { variant_name: 'アルトミスト 200ml スプレーボトル', chemical_product_variants_id: '3568', ranking_column_number: '12' },
                          { variant_name: 'The oil for styling (スタイリングオイル)', chemical_product_variants_id: '64', ranking_column_number: '13' },
                          { variant_name: 'パラトリートメントプラス 1000ml詰め替え用レフィル', chemical_product_variants_id: '3580', ranking_column_number: '14' },
                          { variant_name: 'エスハートエス　スカルプブラシプラス　プリュスショートシルバー', chemical_product_variants_id: '574', ranking_column_number: '15' },]
      
      referral_suidbase = 111793;
            
      html += "<div class='title'>まだ購入商品はありません</div>";
    //   html += "<div class='shokai-otoku-text'>初回限定" + chemical_growth_discount_first_percent + "％OFF！</div>";
    //   html += "<div class='ranking-title'>今人気の商品ランキング</div>";
    //   html += "<ul id='products-ranking'>";



    //   // 商品ランキング
    //   for (var i = 0; i < ranking_products.length; i++) {
    //     let product = ranking_products[i];

    //     html += "<li class='products-ranking-item' >";
    //     html += "<div class='ranking-column-number'>" + product.ranking_column_number + "位　</div>";
    //     html += "<div class='products-ranking-item-name'>";
    //     html += "<a class='products-ranking-item-link' href='" + liff_full_url + "&move_to=ec&referral_suidbase=" + referral_suidbase + "&product_variants_id=" + product.chemical_product_variants_id + "&from_purchase_history=true'>" + product.variant_name + "</a>";
    //     html += "</div>";
    //     html += "</li>";


    //   }
    //   html += "</ul>";
    //   html += "<div class='products-search-item' >";
    //   html += "<a class='products-search-link' href='" + liff_full_url + "&move_to=product-search&ruid=" + ruid + "'>他の商品を検索する</a>";
    //   html += "</div>";
    }

    if (isNewYearHolidaySeason() == true) {

    html += "<div class='new-year-schedule'>";
    html += "<p class='nenmatu-yotei'>年末年始は通常とは配送が異なります</p>";
    html += "<p class='nenmatu-yotei'>商品により1/5以降の発送になる場合があります</p>";
    html += "</div>";
      
    }
    // alert("できたhtml"+ html)
    $(".wrap-content.purchase-history").html(html);

    // 画像の遅延読み込み
    const imageElements = document.querySelectorAll('.wrap-content.purchase-history img[data-src]');
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          const src = image.getAttribute('data-src');
          image.setAttribute('src', src);
          image.removeAttribute('data-src');
          observer.unobserve(image);
        }
      });
    });

    imageElements.forEach((image) => {
      imageObserver.observe(image);
    });

    // ふわふわをフェードアウト
    $('.unread-badge').fadeOut();
  });
}


//文字のバイト数を取得する関数
function charcount(str) {
  var len = 0;
  var str = escape(str);
  for (var i = 0; i < str.length; i++, len++) {
    if (str.charAt(i) == "%") {
      if (str.charAt(++i) == "u") {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

function showCheerDialog() {
  var reviewed = $('.already-reviewed').length
  //レビュー済みの場合は表示しない
  if (reviewed == 0) {
    // 美容師さんを応援しませんかダイアログ
    $('.cheer-for-stylist-dialog-wrap').fadeIn()
    $('.cheer-for-stylist-dialog').fadeIn()

    //名前が長い時の文字数対策
    var custmername_text = $(".name-client-charcount").text();
    var custmername_count = charcount(custmername_text);
    if (custmername_count > 18) {
      $(".name-client-charcount").css('font-size', (18 - ((custmername_count - 18) * 0.37)) + 'px');
      $(".name-client-charcount").css('letter-spacing', '-0.5px');  
    }
  }
}

//
// キャンセル・予約変更導線表示制御
//
function checkReserveCancelPermition(r_reserve_id) {
  $("a.cancel-reservation-button").attr("data-cancel-reserve-id", r_reserve_id)
  $("a.change-reservation-button").attr("data-change-reserve-id", r_reserve_id)
  //キャンセルできて良いかAPIアクセス
  Ajax.checkReserveCancelPermition(r_reserve_id, (permit_cancel) => {
    if (permit_cancel) {
      $(".cancel-or-change-dialog").text("予約を変更・またはキャンセル")
      $(".cancel-or-change-dialog").data("permit-cancel", 1)
      $(".cancel-or-change-dialog").fadeIn()
    } else {
      $(".cancel-or-change-dialog").text("予約を変更する")
      $(".cancel-or-change-dialog").data("permit-cancel", 0)
      $(".cancel-or-change-dialog").fadeIn()
    }
  }, () => {
  })
}

//
// 予約キャンセル
//
function cancelReservation(ruid, r_reserve_id) {
  Ajax.cancelReservation(ruid, r_reserve_id, (data) => {
    // //キャンセル完了ダイアログ表示
    $('.common-dialog-mykarte-bg').hide()
    $('.common-dialog-mykarte-bg-untouchable').show()
    $('.canceled-reserve-dialog').show();
  }, () => {
    //キャンセル失敗
    $('.failed-cancel-reserve-dialog').show();
  })
}

//
// 予約変更
//
function changeReservation(r_reserve_id) {
  var channel_id = getUrlParameter('channel_id')
  Ajax.getUpdateReservationUrl(r_reserve_id, channel_id, (update_reserve_url) => {
    if (update_reserve_url) {
      location.href = update_reserve_url
    } else {
      $('.failed-cancel-reserve-dialog').show();
    }
  }, () => {
    $('.failed-cancel-reserve-dialog').show();
  })
}

/**
 * アニメーションつきでステキ数のセットを行う
 */
function setStekiWithAnimate (loveButton, numSteki) {
  var loveButtonParent = loveButton.parent()
  loveButton.data('num-suteki', numSteki)
  loveButton.data('num-total-suteki', numSteki)
  loveButton.addClass('sutekiscalenormal').delay(500).queue(function (next) {
    loveButton.removeClass('sutekiscalenormal')
    next()
  })

  loveButtonParent.find('.suteki-counter').animate({
    'opacity' : '1',
  }, 0)

  loveButtonParent.find('.suteki-counter').fadeIn('100')
  loveButtonParent.find('.suteki-counter').fadeOut('5000')
  loveButtonParent.find('.mask-color').css('height', STEKI_METER_HEIGHTS[numSteki])

  if (numSteki >= 10) {
    loveButtonParent.find('.flush-heart').addClass('target')
    loveButtonParent.addClass('liked', 400, 'swing', () => {
      loveButtonParent.find('.flush-heart').removeClass('target')
    })
    loveButtonParent.find('.mask-color').css('background', 'white')
    loveButtonParent.find('.love-button').addClass('suteki-scale')
    loveButtonParent.find('.suteki-counter-number').css('font-size', '30px')
  }

  loveButtonParent.find('.love-button-count-span').html(function (i, val) {
    return numSteki
  })
  loveButtonParent.find('.suteki-counter-number').html(function (i, val) {
    return numSteki
  })
}


//
// Cropper
//
$(function () {
  // 画像選択時
  var kSuidbase = ''
  var kSuid = ''
  var photoid = ''
  // 新規登録
  $('.private-karte-photos').on('change', '.launch-crop-new-image', function (e) {
    var file = e.target.files[0]
    if (file === undefined || file.type.indexOf('image') < 0) {
      alert('画像を選択してください')
      return false
    }
    kSuidbase = $('.private-area').attr('data-ksuidbase')
    kSuid = $('.private-area').attr('data-ksuid')
    photoid = $(this).attr('data-photoid')
    launchCropper(file)
  })

  // 更新
  $('.private-karte-photo-edit-dialog').on('change', '.launch-crop-edit-image', function (e) {
    $('.private-karte-photo-edit-dialog').hide()
    var file = e.target.files[0]
    if (file === undefined || file.type.indexOf('image') < 0) {
      alert('画像を選択してください')
      return false
    }
    kSuidbase = $('.private-area').attr('data-ksuidbase')
    kSuid = $('.private-area').attr('data-ksuid')
    photoid = $('.launch-crop-edit-image').attr('data-photoid')
    launchCropper(file)
  })

  function launchCropper (file) {
    var reader = new FileReader()
    // トリミング画面をフェードインさせる
    reader.onload = (function (e) {
      $('.overlay').fadeIn()
      $('.crop_modal').append($('<img>').attr({
        src: e.target.result,
        height: '100%',
        class: 'preview',
        id: 'crop_img',
        title: file.name
      }))

      var aspectRatio = 1
      initIconCrop(aspectRatio)
    })
    reader.readAsDataURL(file)
  }

  // cropper（トリミング部）のコーディング（詳しくはGitHub参照）
  var cropper
  var croppable = false
  function initIconCrop (aspectRatio) {
    cropper = new Cropper(crop_img, {
      dragMode: 'move',
      aspectRatio: aspectRatio,
      viewMode: 1,
      restore: false,
      guides: false,
      center: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      ready: function () {
        croppable = true
      }
    })
  }

  // croppedCanvas（トリミング後の画像をプレビューとして表示するための部分）のコーディング
  var croppedCanvas
  function iconCropping () {
    if (!croppable) {
      alert('トリミングする画像が設定されていません。')
      return false
    }
    croppedCanvas = cropper.getCroppedCanvas({
      width: 800,
      height: 800
    })
    var croppedImage = document.createElement('img')
    croppedImage.src = croppedCanvas.toDataURL()

    // 画像アップロード
    Ajax.setPrivateKartePhoto(kSuid, kSuidbase, photoid, croppedImage.src, (result) => {
      // nothing to do
    })
  }

  // トリミング決定時
  $('.select_icon_btn').on('click', function () {
    iconCropping()
    $('.overlay').fadeOut()
    $('#crop_img').remove()
    $('.cropper-container').remove()
  })

  // トリミング画面を閉じる時
  $('.close_btn').on('click', function () {
    $('.overlay').fadeOut()
    $('#crop_img').remove()
    $('.cropper-container').remove()
  })
})

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
  }
}

//【速度改善】ページ読み込んで0.3秒後に3枚目以降のカルテを表示
document.addEventListener("DOMContentLoaded", function() {
  setTimeout(function() {
    var archiveListItems = document.querySelectorAll(".common-mykarte-archive-list");

    for (var i = 2; i < archiveListItems.length; i++) {
      archiveListItems[i].style.display = "block";
    }
  }, 300);
});
